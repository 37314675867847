import React from "react";
import ReactDOM from "react-dom";

import Application from "./Application";

ReactDOM.render(<Application />, document.querySelector("#root"));

// Without Semantic UI
// 67.95 KB  build/static/js/2.75a20cc0.chunk.js
// 2.17 KB   build/static/js/main.d3fb2a60.chunk.js
// 940 B     build/static/css/main.0a0fd385.chunk.css
// 783 B     build/static/js/runtime-main.364e416b.js

// With Semantic Ui popup
// 96.53 KB              build/static/css/2.093024ec.chunk.css
// 96.18 KB (+28.23 KB)  build/static/js/2.06d9eef6.chunk.js
// 2.25 KB (+82 B)       build/static/js/main.80e3e26c.chunk.js
// 940 B                 build/static/css/main.0a0fd385.chunk.css
// 783 B                 build/static/js/runtime-main.364e416b.js

// Before Recoil
// 96.53 KB             build/static/css/2.7dce832d.chunk.css
// 92.78 KB (-3.39 KB)  build/static/js/2.60f19161.chunk.js
// 2.89 KB (+653 B)     build/static/js/main.822b49cc.chunk.js
// 1.16 KB (+250 B)     build/static/css/main.1932a5a6.chunk.css
// 783 B                build/static/js/runtime-main.364e416b.js

// After Recoil
// 116.55 KB (+23.77 KB)  build/static/js/2.9811eb00.chunk.js
// 96.53 KB               build/static/css/2.093024ec.chunk.css
// 3.01 KB (+124 B)       build/static/js/main.f7a61253.chunk.js
// 1.16 KB                build/static/css/main.1932a5a6.chunk.css
// 783 B                  build/static/js/runtime-main.364e416b.js

//Slider with zoom libs
// https://codesandbox.io/s/0l13f?file=/src/ZoomImageModal.js
// https://www.npmjs.com/package/react-quick-pinch-zoom
