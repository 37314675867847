import React from "react";
import { Route, Switch } from "react-router-dom";
// import BookView from "./bookview/BookView";
import BookWrapper from "./bookview/BookWrapper";
import styles from "./styles/App.module.css";

const App = () => {
    // if (BOOKID) {
    //     return (
    //         <div className={styles.wrapper}>
    //             {/* <SEO /> */}
    //             {/* <SlideView /> */}
    //             <Switch>
    //                 <Route exact path="/" component={BookWrapper} />
    //                 <Route exact path="/p/:pno" component={BookWrapper} />
    //             </Switch>
    //         </div>
    //     );
    // }
    return (
        <div className={styles.wrapper}>
            {/* <SEO /> */}
            {/* <SlideView /> */}
            <Switch>
                <Route exact path="/" component={BookWrapper} />
                <Route exact path="/p/:pno" component={BookWrapper} />
                <Route exact path="/book/:bid" component={BookWrapper} />
                <Route path="/book/:bid/page/:pno" component={BookWrapper} />
                <Route exact path="/b/:bid" component={BookWrapper} />
                <Route path="/b/:bid/p/:pno" component={BookWrapper} />
                <Route exact path="/m/:bid" component={BookWrapper} />
                <Route path="/m/:bid/p/:pno" component={BookWrapper} />
            </Switch>
        </div>
    );
};

export default App;
