import styles from "./styles/Loading.module.css";
import React from "react";

const Loading = ({ text, progress = -1, dark = true }) => {
    return (
        <div
            className={styles.wrapper}
            style={{
                background: dark ? "rgba(0, 0, 0, 0.493)" : "transparent",
            }}
        >
            {progress === -1 && (
                <div>
                    <span
                        className={styles.loader18}
                        style={{
                            border: dark
                                ? "5px dotted white"
                                : "5px dotted black",
                        }}
                    ></span>
                </div>
            )}
            {progress !== -1 && (
                <img
                    className={styles.logo}
                    src={process.env.PUBLIC_URL + "/Qureal_logo.png"}
                    alt="Loading..."
                />
            )}
            <p
                className={styles.text}
                style={{
                    color: dark ? "white" : "black",
                }}
            >
                {text}
            </p>
            {progress !== -1 && (
                <div className={styles.progress_wrapper}>
                    <div
                        className={styles.progressbar}
                        style={{
                            width: `${progress}%`,
                        }}
                    >
                        ..
                    </div>
                </div>
            )}
        </div>
    );
};

export default Loading;
