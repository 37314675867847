import React from "react";
import Loadable from "react-loadable";
import Loading from "./Loading";
import useMedia from "react-use/lib/useMedia";

const BookLoadable = Loadable({
    loader: () => import("./BookViewDesktop"),
    loading: () => (
        <Loading dark={false} text="Loading magazine, please wait..." />
    ),
});

const BookMobileLoadable = Loadable({
    loader: () => import("./BookViewMobile"),
    loading: () => (
        <Loading dark={false} text="Loading magazine, please wait..." />
    ),
});

const BookWrapper = () => {
    const isMobile = useMedia("(max-width: 900px)");

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                // backgroundColor: "darkblue",
                backgroundColor: "#ebebed",
            }}
        >
            <BookLoadable />
            {/* {isMobile ? <BookMobileLoadable /> : <BookLoadable />} */}
        </div>
    );
};

export default BookWrapper;
