import "./styles/reset.css";
import "./styles/global.css";
import React from "react";
import "semantic-ui-css/semantic.min.css";
import "./styles/qhover.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faSearchPlus,
    faSearchMinus,
    faPrint,
    faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

library.add(
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faSearchPlus,
    faSearchMinus,
    faPrint,
    faExpand
);

const Application = () => {
    return (
        <RecoilRoot>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>
    );
};

export default Application;
